import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { TableHeader } from 'components/table/table-content';
import { PaginationHandler } from 'elements/pagination-handler';
import { CertificateProducer, MooringCertificate, MooringCertificateListItem } from 'models';
import {
  MooringArticleCategoryService,
  MooringArticleTypeService,
  MooringCertificateService,
  MooringProductTypeService,
} from 'services';
import { ErrorService } from 'services/error-service';
import { ToastService } from 'services/toast-service';
import { Router } from 'aurelia-router';
import { DataFilter } from 'lib/tables/DataFilter';
import { PubSub } from 'lib/event/PubSub';
import { getCallbackData } from 'elements/filterer';
import { Filters, filterTypes } from 'elements/Filter';
import { DialogService } from 'aurelia-dialog';
import { CertificateDialog } from 'views/mooring/mooring-certificate/certificate-dialog';
import { TabManager } from 'views/admin/mooring/tab-manager';
import { CustomerService } from 'services/customer-service';
import { SiteService } from 'services/site-service';
import { SystemFileStorageService } from 'services/system-file-storage-service';

type TableFilter = {
  customerIds?: string[];
  localityIds?: string[];
  dateOfGoodsReceiptFrom?: string;
  dateOfGoodsReceiptTo?: string;
  categoryIds?: string[];
  typeIds?: string[];
  productIds?: string[];
} & DataFilter;

@autoinject()
export class TabMooringComponentCertificate {
  protected certificates: MooringCertificateListItem[] = [];
  protected dataFilter: TableFilter = new DataFilter();

  protected tableHeaders: TableHeader<MooringCertificateListItem & { actions: '' }>[] = [
    { key: 'ApprovedAt', label: '', sortable: false },
    { key: 'Id', label: 'general.id', sortable: true },

    { key: 'SupplierName', label: 'general.supplier', sortable: true },
    { key: 'CertificateProducer', label: 'general.certificate', sortable: true },
    { key: 'CategoryName', label: 'general.category', sortable: true },
    { key: 'TypeName', label: 'general.type', sortable: true },
    { key: 'ProductTypeName', label: 'general.product', sortable: true },

    { key: 'ArticleNo', label: 'general.articlenr', sortable: true },
    { key: 'ProductName', label: 'general.productName', sortable: true },

    { key: 'BatchNumber', label: 'general.batchNumber', sortable: true },
    { key: 'BatchNumberTo', label: 'general.batchNumber', sortable: true },

    { key: 'DateOfGoodsReceipt', label: 'general.goodsReceipt', sortable: true },
    { key: 'Quantity', label: 'general.quantity', sortable: true },

    { key: 'actions', label: '', sortable: false },
  ];

  constructor(
    private errorService: ErrorService,
    private certificateService: MooringCertificateService,
    private toast: ToastService,
    private router: Router,
    private pagination: PaginationHandler,
    private dialogService: DialogService,
    private pubsub: PubSub,
    private t: I18N,
    private tabManager: TabManager,
    private customerService: CustomerService,
    private siteService: SiteService,
    private mooringArticleCategoryService: MooringArticleCategoryService,
    private mooringArticleTypeService: MooringArticleTypeService,
    private mooringProductTypeService: MooringProductTypeService,
    private systemFilesService: SystemFileStorageService
  ) {
    tabManager.tabActivated();
  }

  protected getCertificateProducer(certificateProducer: CertificateProducer) {
    const p = +certificateProducer;
    if (isNaN(p) || !Number.isInteger(p)) return '';
    if (certificateProducer === CertificateProducer.MORENOT) return 'Mørenot';
    if (certificateProducer === CertificateProducer.SUPPLIER) return this.t.tr('general.supplier');
  }

  protected setupPagination() {
    const routeParams = this.router.currentInstruction?.queryParams;
    let currentPage = 1;
    if (routeParams && routeParams.currentPage) {
      currentPage = +routeParams.currentPage;
    }

    this.pubsub.sub('filter-section:search-text-changed', (data) => {
      if (data.context !== this.context) return;
      this.dataFilter.searchText = data.searchText;
      void this.getCertificates();
    });

    this.pubsub.sub('filter-section:reset-filters', (data) => {
      if (data.context !== this.context) return;
      this.filterClearAll();
    });

    this.pubsub.sub('filter-section:list-settings-changed', (data) => {
      if (this.dataFilter.top === data.settings.pageSize) return;

      this.dataFilter.top = data.settings.pageSize;
      this.pagination.pageSize = data.settings.pageSize;
      this.pagination.currentPage = 1;
      void this.getCertificates();
    });

    this.pubsub.sub('export-list', (data) => {
      if (data === 'mooring-certificates') {
        void this.getCertificates({ export: true });
      }
    });

    this.pubsub.sub('list-entity:created', () => {
      void this.getCertificates();
    });

    this.pubsub.sub('list-entity:updated', () => {
      void this.getCertificates();
    });

    this.pagination.init({
      currentPage: currentPage,
      totalItems: 0,
      onPageChanged: (_, pagination) => {
        this.dataFilter.skip = pagination.skip;
        this.dataFilter.top = pagination.top;
        void this.getCertificates();
      },
    });
  }

  protected async onDelete(id: number) {
    try {
      if (id) {
        this.toast.showSuccess(this.t.tr('general.deleted'));
        await this.getCertificates();
      }
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected async onSave(certificate: MooringCertificate) {
    try {
      if (certificate.Id) {
        await this.certificateService.put(certificate, certificate.Id);
      } else {
        await this.certificateService.post(certificate);
      }
      this.toast.showSuccess(this.t.tr('general.saved'));
      await this.getCertificates();
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected editCertificate(_: number, id: number) {
    void this.dialogService
      .open({
        viewModel: CertificateDialog,
        model: {
          certificateId: id,
        },
      })
      .whenClosed(() => {
        document.querySelector('html').style.overflowY = null;
        void this.getCertificates();
      });
  }

  async attached() {
    this.setupPagination();
    await this.getCertificates();

    const certificateId = this.router.currentInstruction.queryParams['Id'];
    if (certificateId) {
      setTimeout(() => {
        this.editCertificate(null, +certificateId);
      }, 200);
    }
  }

  protected detached() {
    this.pubsub.unsub();
  }

  protected setOrderByColumn(column: string) {
    this.dataFilter.setOrderByAndFlipOrderDirectionIfSameKey(column);
    void this.getCertificates();
  }

  protected downloadCertificate(attachmentId: number) {
    try {
      this.systemFilesService.download(attachmentId, true);
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected context = 'mooring_certificates';
  protected filterClearAll: () => void;
  protected filterClearSingle: (name: string) => void;
  protected filterToggleVisible: () => void;
  protected filterGetFiltersQuery: () => Promise<{
    customerIds?: string[];
    localityIds?: string[];
    dateOfGoodsReceiptFrom?: string;
    dateOfGoodsReceiptTo?: string;
    categoryIds?: string[];
    typeIds?: string[];
    productIds?: string[];
  }>;
  protected setFilterValueByName: (name: string, data: any) => void;

  protected defaultFilters = {
    customerIds: true,
    localityIds: true,
    dateOfGoodsReceipt: true,
    categoryIds: true,
    typeIds: true,
    productIds: true,
  };

  protected getFilterKey() {
    return 'MOORING_CERTIFICATES_TABLE';
  }

  protected onFilterChanged() {
    void this.getCertificates();
  }

  getFilterValues() {
    return getCallbackData(this.filterGetFiltersQuery);
  }

  protected async setupFilters() {
    return await new Promise((res: (v: Filters) => void) => {
      setTimeout(async () => {
        const customerJob = this.customerService.getAll();
        const siteJob = this.siteService.getAll();
        const mooringArticleCategoriesJob = this.mooringArticleCategoryService.getAll();
        const mooringArticleTypesJob = this.mooringArticleTypeService.getAll();
        const mooringProductTypesJob = this.mooringProductTypeService.getAll();

        const [customers, localitys, mooringArticleCategories, mooringArticleTypes, mooringProductTypes] =
          await Promise.all([
            customerJob,
            siteJob,
            mooringArticleCategoriesJob,
            mooringArticleTypesJob,
            mooringProductTypesJob,
          ]);

        const filters: Filters = {
          customerIds: {
            name: 'customerIds',
            label: this.t.tr('general.customer'),
            type: filterTypes.CHECKBOX,
            options: customers,
            query: 'customerIds',
          },
          localityIds: {
            name: 'localityIds',
            label: this.t.tr('general.locality'),
            type: filterTypes.CHECKBOX,
            options: localitys,
            query: 'localityIds',
          },
          dateOfGoodsReceipt: {
            name: 'dateOfGoodsReceipt',
            label: this.t.tr('general.goodsReceipt'),
            type: filterTypes.RANGE_DATE,
          },
          categoryIds: {
            name: 'categoryIds',
            label: this.t.tr('general.category'),
            type: filterTypes.CHECKBOX,
            options: mooringArticleCategories,
            query: 'categoryIds',
          },
          typeIds: {
            name: 'typeIds',
            label: this.t.tr('general.type'),
            type: filterTypes.CHECKBOX,
            options: mooringArticleTypes,
            query: 'typeIds',
          },
          productIds: {
            name: 'productIds',
            label: this.t.tr('general.product'),
            type: filterTypes.CHECKBOX,
            options: mooringProductTypes,
            query: 'productIds',
          },
        };
        res(filters);
      });
    });
  }

  protected async getCertificates(options?: { export: boolean }) {
    try {
      const filters = await this.filterGetFiltersQuery?.();

      this.dataFilter.export = options?.export || false;
      this.dataFilter.customerIds = filters?.customerIds || [];
      this.dataFilter.localityIds = filters?.localityIds || [];
      this.dataFilter.dateOfGoodsReceiptFrom = filters?.dateOfGoodsReceiptFrom || '';
      this.dataFilter.dateOfGoodsReceiptTo = filters?.dateOfGoodsReceiptTo || '';
      this.dataFilter.categoryIds = filters?.categoryIds || [];
      this.dataFilter.typeIds = filters?.typeIds || [];
      this.dataFilter.productIds = filters?.productIds || [];

      // this.dataFilter.locale = this.i18n.getLocale() as 'en' | 'nb' | 'es';

      const response = await this.certificateService.table(this.dataFilter);
      if (!response) return;
      this.pagination.totalItems = +response.headers.get('x-total-count') || 0;
      this.certificates = response.data;
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  // Aurelia lifecycle
  protected canDeactivate() {
    return this.tabManager.leaveTab();
  }
}
